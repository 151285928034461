import { Link, graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import * as s from '~/components/pages/blog/blog.module.scss'
import Arrow from '~/images/common_icon_arrow_r.svg'
import { Seo, Pagination, TagList } from '~/components'
// import { CategoryList, AllWpTags } from '~/components'

export default function BlogIndex({ data, pageContext }) {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return <p>No Found</p>
  }

  /* 後ほど復活予定、カテゴリへのリスト
  <dl className="contentComponent categoryComponent">
    <dt className="heading">CATEGORY</dt>
    <CategoryList />
  </dl>
  <div className={s.currentCategory}>
    <h2 className="heading contentComponent">すべての読みもの</h2>
  </div>

  タグ一覧
  <dl className="contentComponent tagListComponent mincho">
    <dt className="heading">TAG</dt>
    <AllWpTags />
  </dl>
  */

  return (
    <>
      <Seo title="Blog" />

      <div className="blog">
        <section className="contentComponent">
          <header className="titleComponent">
            <h1 className="heading pageTitle">SNISH MAGAZINE</h1>
            <p>
              人気ブランドから新鋭ブランドまで、最先端技術で作られる環境に配慮したサスティナブルスニーカー情報をお届けします。
            </p>
          </header>
          <ul className="breadcrumbComponent">
            <li>
              <Link to="/" itemProp="url">
                TOP
              </Link>{' '}
              <span>
                <Arrow />
              </span>
            </li>
            <li>BLOG</li>
          </ul>
          <div className={s.articleList}>
            {posts.map((post, index) => {
              const title = post.title
              const featuredImage = {
                image:
                  post.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData,
                alt: post.featuredImage?.node?.alt || ``,
              }
              const category = {
                name: post.categories?.nodes[0]?.name,
                uri: post.categories?.nodes[0]?.uri,
              }
              const tags = post.tags?.nodes

              return (
                <article
                  className={s.articleItem}
                  itemScope
                  itemType="http://schema.org/Article"
                  key={`${post.id}-${index}`}
                >
                  <Link to={`/blog${post.uri}`} itemProp="url">
                    <div className={s.thumbnail}>
                      {featuredImage?.image ? (
                        <GatsbyImage
                          image={featuredImage.image}
                          alt={featuredImage.alt}
                        />
                      ) : (
                        <StaticImage
                          src="../images/top_mv_pc.jpg"
                          alt=""
                          placeholder="blurred"
                          layout="fullWidth"
                        />
                      )}
                    </div>
                    <section className={s.articleDetail}>
                      <header>
                        {category?.name && (
                          <div className={s.articleCategory}>
                            <span>{category.name}</span>
                          </div>
                        )}
                        <h2
                          className={s.articleTitle + ' heading is-secondary'}
                        >
                          <span itemProp="headline">{parse(title)}</span>
                        </h2>
                      </header>
                      <small className={s.articleDate + ' heading'}>
                        {post.date} UP
                      </small>
                      {tags.length > 0 && <TagList type={'tag'} tags={tags} />}
                    </section>
                  </Link>
                </article>
              )
            })}
          </div>
        </section>

        <Pagination pageContext={pageContext} />
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        uri
        date(formatString: "YYYY-MM-DD")
        title
        excerpt
        tags {
          nodes {
            uri
            name
            slug
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
      }
    }
  }
`
